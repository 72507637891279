import React, { lazy, Suspense } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import { SecondaryLayout } from "./layout/index";
import {
  AdminCheckUpdatePass,
  agentAuth,
  CheckAuth,
  CheckLoggedIn,
  CheckUpdatePass,
} from "./utils/auth";
import RolesAuthentication from "./utils/RolesAuthentication";

const Login = lazy(() => import("./pages/login"));
const Register = lazy(() => import("./pages/register"));
const MainLayout = lazy(() => import("./layout/index"));
const Profile = lazy(() => import("./pages/myProfile"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const UserCreation = lazy(() => import("./pages/master/userCreation"));
const UserUpdate = lazy(() =>
  import("./pages/master/userCreation/update/UserUpdate")
);
const UserList = lazy(() => import("./pages/list/userList/UserList"));
const AgencyKyc = lazy(() => import("./pages/master/agencyKyc"));
const AgencyList = lazy(() => import("./pages/list/agencyList/AgencyList"));
const BranchCreation = lazy(() => import("./pages/master/branchCreation"));
const BranchList = lazy(() => import("./pages/list/branchList/BranchList"));
const ProductCreation = lazy(() => import("./pages/master/productCreation"));
const UpdateProduct = lazy(() =>
  import("./pages/master/productCreation/update/UpdateProduct")
);
const ProductList = lazy(() => import("./pages/list/productList/ProductList"));
const VendorCreation = lazy(() => import("./pages/master/vendorCreation"));
const VendorPricing = lazy(() =>
  import("./pages/master/vendorCreation/vendorPricing")
);
const VendorList = lazy(() => import("./pages/list/vendorList/VendorList"));
const EnquiryList = lazy(() => import("./pages/list/enquiryList/EnquiryList"));
const Currency = lazy(() => import("./pages/master/mas/currency"));
const ProductDetails = lazy(() =>
  import("./pages/master/productDetails/ProductDetails")
);
const AgentForgetPass = lazy(() =>
  import("./pages/forgetPass/AgentForgetPass/AgentForgetPass")
);
const AgentNewPassword = lazy(() =>
  import("./pages/forgetPass/AgentForgetPass/AgentNewPassword")
);
const AdminForgetPass = lazy(() =>
  import("./pages/forgetPass/AdminForgetPass/AdminForgetPass")
);
const AdminNewPassword = lazy(() =>
  import("./pages/forgetPass/AdminForgetPass/AdminNewPassword")
);
const PricingPlan = lazy(() => import("./pages/master/pricingPlans"));
const AssignPlan = lazy(() => import("./pages/master/assignPlans"));
const CreatePlans = lazy(() =>
  import("./pages/master/pricingPlans/createPlans")
);
const CreateCareer = lazy(() => import("./pages/master/career/index"));
const CareerList = lazy(() => import("./pages/list/careerList/CareerList"));
const CareerEnquiry = lazy(() => import("./pages/list/careerEnquiry"));
const GeneralEnquiry = lazy(() => import("./pages/list/generalEnquiryList"));
const OrderLists = lazy(() => import("./pages/list/ordersList/OrdersList"));
const OrderUpdate = lazy(() => import("./pages/master/orderUpdate"));
const AgencyWallet = lazy(() => import("./pages/master/wallet/index"));
const OrderReassign = lazy(() => import("./pages/master/orderReassign"));
const OrderView = lazy(() => import("./pages/master/orderView/OrderView"));

/// Agent Routes
const Home = lazy(() => import("./pages/agent/dashboard/AgentDashboard"));
const Order = lazy(() => import("./pages/agent/order/Order"));
const Quotation = lazy(() => import("./pages/agent/quotation/Quotation"));
const Visa = lazy(() => import("./pages/agent/visa"));
const VisaSearch = lazy(() =>
  import("./pages/agent/visa/searchList/SearchList")
);
const Wallet = lazy(() => import("./pages/agent/wallet/index"));
const Apply = lazy(() => import("./pages/agent/visa/apply/index"));
const VisaDetails = lazy(() => import("./pages/agent/visa/visaDetails"));
const AgentProfile = lazy(() => import("./pages/agent/myProfile"));
const Enquiry = lazy(() => import("./pages/agent/myEnquiry/Enquiry"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route loader={CheckLoggedIn} path="/login" element={<Login />} />
      <Route
        loader={CheckUpdatePass}
        path="/agent/new-password"
        element={<AgentNewPassword />}
      />
      <Route path="/agent/forget-password" element={<AgentForgetPass />} />
      <Route
        loader={AdminCheckUpdatePass}
        path="/admin/new-password"
        element={<AdminNewPassword />}
      />
      <Route path="/admin/forget-password" element={<AdminForgetPass />} />
      <Route path="/register" element={<Register />} />
      <Route loader={CheckAuth} path="/">
        <Route element={<MainLayout />}>
          <Route loader={CheckAuth} index element={<Dashboard />} />
          <Route element={<RolesAuthentication allowedRoles={[1, 2]} />}>
            <Route
              loader={CheckAuth}
              path="/user/add"
              element={<UserCreation />}
            />
            <Route
              loader={CheckAuth}
              path="/user/update/:id"
              element={<UserUpdate />}
            />
            <Route
              loader={CheckAuth}
              path="/user/list"
              element={<UserList />}
            />
          </Route>

          <Route
            element={<RolesAuthentication allowedRoles={[1, 2, 3, 7, 8, 9]} />}
          >
            <Route loader={CheckAuth} path="/agency" element={<AgencyList />} />
          </Route>
          <Route element={<RolesAuthentication allowedRoles={[1, 2, 3, 7]} />}>
            <Route
              loader={CheckAuth}
              path="/agency/kyc"
              element={<AgencyKyc />}
            />
          </Route>

          <Route element={<RolesAuthentication allowedRoles={[1, 2, 8, 9]} />}>
            <Route
              loader={CheckAuth}
              path="/agency/wallet"
              element={<AgencyWallet />}
            />
          </Route>

          <Route element={<RolesAuthentication allowedRoles={[1]} />}>
            <Route
              loader={CheckAuth}
              path="/pricing-plan"
              element={<PricingPlan />}
            />
            <Route
              loader={CheckAuth}
              path="/assign-plan"
              element={<AssignPlan />}
            />
            <Route
              loader={CheckAuth}
              path="/create-plans"
              element={<CreatePlans />}
            />
            <Route
              loader={CheckAuth}
              path="/branch/add"
              element={<BranchCreation />}
            />
            <Route
              loader={CheckAuth}
              path="/branch/list"
              element={<BranchList />}
            />
            <Route
              loader={CheckAuth}
              path="/product/add"
              element={<ProductCreation />}
            />
            <Route
              loader={CheckAuth}
              path="/product/update/:id"
              element={<UpdateProduct />}
            />
            <Route
              loader={CheckAuth}
              path="/product/details"
              element={<ProductDetails />}
            />
            <Route
              loader={CheckAuth}
              path="/product/list"
              element={<ProductList />}
            />
            <Route
              loader={CheckAuth}
              path="/master/currency"
              element={<Currency />}
            />
            <Route
              loader={CheckAuth}
              path="/vendor/add"
              element={<VendorCreation />}
            />
            <Route
              loader={CheckAuth}
              path="/vendor/pricing"
              element={<VendorPricing />}
            />
            <Route
              loader={CheckAuth}
              path="/vendor/list"
              element={<VendorList />}
            />
          </Route>
          <Route element={<RolesAuthentication allowedRoles={[1, 2, 3, 7]} />}>
            <Route
              loader={CheckAuth}
              path="/visa-enquiry"
              element={<EnquiryList />}
            />
            <Route
              loader={CheckAuth}
              path="/general-enquiry"
              element={<GeneralEnquiry />}
            />
          </Route>
          <Route element={<RolesAuthentication allowedRoles={[1, 2]} />}>
            <Route
              loader={CheckAuth}
              path="/career/add"
              element={<CreateCareer />}
            />
            <Route
              loader={CheckAuth}
              path="/career/list"
              element={<CareerList />}
            />
            <Route
              loader={CheckAuth}
              path="/career/enquiry"
              element={<CareerEnquiry />}
            />
          </Route>
          <Route
            element={
              <RolesAuthentication allowedRoles={[1, 2, 3, 4, 5, 6, 7]} />
            }
          >
            <Route
              loader={CheckAuth}
              path="/orders-list"
              element={<OrderLists />}
            />

            <Route
              loader={CheckAuth}
              path="/orders-list/order-view/:id"
              element={<OrderView />}
            />
            <Route element={<RolesAuthentication allowedRoles={[1, 2]} />}>
              <Route
                loader={CheckAuth}
                path="/orders-list/order-reassign"
                element={<OrderReassign />}
              />
            </Route>
          </Route>
          <Route loader={CheckAuth} path="/my-profile" element={<Profile />} />
        </Route>
        <Route
          element={<RolesAuthentication allowedRoles={[1, 2, 3, 4, 5, 6, 7]} />}
        >
          <Route
            loader={CheckAuth}
            path="/orders-list/orders-update/:id"
            element={<OrderUpdate />}
          />
        </Route>
      </Route>

      <Route loader={agentAuth} path="/agent" element={<SecondaryLayout />}>
        <Route
          loader={agentAuth}
          path="/agent/my-profile"
          element={<AgentProfile />}
        />
        <Route loader={agentAuth} path="/agent/dashboard" element={<Home />} />
        <Route loader={agentAuth} path="/agent/order" element={<Order />} />
        <Route
          loader={agentAuth}
          path="/agent/quotation"
          element={<Quotation />}
        />
        <Route loader={agentAuth} path="/agent/wallet" element={<Wallet />} />
        <Route loader={agentAuth} path="/agent/visa" element={<Visa />} />
        <Route
          loader={agentAuth}
          path="/agent/visa/search"
          element={<VisaSearch />}
        />
        <Route
          loader={agentAuth}
          path="/agent/visa-enquiry"
          element={<Enquiry />}
        />
        <Route
          loader={agentAuth}
          path="/agent/visa/details"
          element={<VisaDetails />}
        />
        <Route
          loader={agentAuth}
          path="/agent/visa/apply"
          element={<Apply />}
        />
        <Route
          loader={agentAuth}
          path="/agent/visa/order-update/:id"
          element={<Apply />}
        />
      </Route>
    </>
  )
);

const Routes = () => {
  return (
    <Suspense
      fallback={
        <div className="loader">
          <img className="load" src="/assets/loaders/loader.svg" />
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Routes;
