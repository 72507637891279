import axios from "axios";
import { Navigate, redirect } from "react-router-dom";

export function getAuthToken() {
  const token = localStorage.getItem("token");
  if (!token) {
    return null;
  }
  return token;
}

export function getAdminId() {
  const id = localStorage.getItem("userId");
  if (!id) {
    return null;
  }
  return id;
}
export function getRole() {
  const role = localStorage.getItem("role0");
  if (!role) {
    return null;
  }
  return role;
}

export function CheckAuth() {
  //  const agentToken= localStorage?.getItem("tokenAgent");
  //   const agentId= localStorage?.getItem("ad");
  //   const agencyN= localStorage?.getItem("agencyN");
  //   const agentDc =localStorage?.getItem("dc");
  //   const agentName=localStorage?.getItem("agn");
  //   const agentCtc= localStorage?.getItem("ctc");
  //   const agentProfile=localStorage?.getItem("userProfile");
  //   const agentLogo= localStorage?.getItem("logoImage");
  //   const agentCode =localStorage?.getItem("code");
  //   const agentWalletId= localStorage?.getItem("wd");

  // if(agentToken||agentId||agencyN||agentDc||agentName||agentCtc||agentProfile||agentLogo||agentCode||agentWalletId
  // ){
  //   localStorage.removeItem("agentToken")
  //   localStorage.removeItem("agentId")
  //   localStorage.removeItem("agencyN")
  //   localStorage.removeItem("agentDc")
  //   localStorage.removeItem("agentName")
  //   localStorage.removeItem("agentCtc")
  //   localStorage.removeItem("agentProfile")
  //   localStorage.removeItem("agentLogo")
  //   localStorage.removeItem("agentCode")
  //   localStorage.removeItem("agentWalletId")
  // }
  const id = getAdminId();
  const token = getAuthToken();
  if (!token || !id) {
    return redirect("/login");
  }
  return null;
}

export function CheckSuperAuth() {
  const id = getAdminId();
  const token = getAuthToken();
  const role = getRole();
  if (!token || !id) {
    return redirect("/login");
  }
  if (role === "Superadmin") {
    return null;
  }
  return redirect("/login");
}

export function CheckLoggedIn() {
  const token = getAuthToken();
  const id = getAdminId();
  if (token && id) {
    return redirect("/");
  }
  return null;
}

export function getAgentId() {
  const ad = localStorage.getItem("ad");
  if (!ad) {
    return null;
  }
  return ad;
}

export function getAgentToken() {
  const tokenAgent = localStorage.getItem("tokenAgent");
  if (!tokenAgent) {
    return null;
  }
  return tokenAgent;
}

export const agentAuth = () => {
  // const adminToken=localStorage.getItem("token")
  // const adminUserName=localStorage.getItem("username")
  // const adminUserId=localStorage.getItem("userId")
  // const adminProfilePic=localStorage.getItem("profilePic")
  // const adminRoles=localStorage.getItem("roles")
  // const adminCurrentRole=localStorage.getItem("currentRole")
  // if(adminToken||adminUserName||adminUserId||adminProfilePic||adminRoles||adminCurrentRole){
  //   localStorage.removeItem("token")
  //   localStorage.removeItem("username")
  //   localStorage.removeItem("userId")
  //   localStorage.removeItem("profilePic")
  //   localStorage.removeItem("roles")
  //   localStorage.removeItem("currentRole")
  // }
  const res = new URLSearchParams(window.location.search);
  const token = res?.get("t");
  const identifier = res?.get("ad");
  const un = res?.get("un");
  const dc = res?.get("dc");
  const agn = res?.get("agn");
  const ctc = res?.get("ctc");
  const prof = res?.get("prof");
  const logo = res?.get("logo");
  const code = res?.get("code");

  if (token && identifier) {
    localStorage.clear()
    localStorage?.setItem("tokenAgent", token);
    localStorage?.setItem("ad", identifier);
    localStorage?.setItem("agencyN", un);
    localStorage?.setItem("dc", dc);
    localStorage?.setItem("agn", agn);
    localStorage?.setItem("ctc", ctc);
    localStorage?.setItem("userProfile", prof);
    localStorage?.setItem("logoImage", logo);
    localStorage?.setItem("code", code);
    localStorage?.setItem("wd", 1);
    window.location.search = "";
  } else {
    const id = getAgentId();
    const token = getAgentToken();
    if (!token || !id) {
      window.location.href = "//web.travelofy.com/";
    }
  }
  return null;
};

export const getAuthPasstkn = () => {
  const token = localStorage.getItem("updtknath");
  if (!token) {
    return null;
  }
  return token;
};
export function CheckUpdatePass() {
  const token = getAuthPasstkn();
  if (!token) {
    window.location.href = "//web.travelofy.com/";
  }
  return null;
}
export function AdminCheckUpdatePass() {
  const token = getAuthPasstkn();
  if (!token) {
    return redirect("/login");
  }
  return null;
}
